// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academic-js": () => import("./../../../src/pages/academic.js" /* webpackChunkName: "component---src-pages-academic-js" */),
  "component---src-pages-arrangements-js": () => import("./../../../src/pages/arrangements.js" /* webpackChunkName: "component---src-pages-arrangements-js" */),
  "component---src-pages-conducting-js": () => import("./../../../src/pages/conducting.js" /* webpackChunkName: "component---src-pages-conducting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-supervision-js": () => import("./../../../src/pages/supervision.js" /* webpackChunkName: "component---src-pages-supervision-js" */),
  "component---src-templates-engagement-js": () => import("./../../../src/templates/engagement.js" /* webpackChunkName: "component---src-templates-engagement-js" */),
  "component---src-templates-engagements-js": () => import("./../../../src/templates/engagements.js" /* webpackChunkName: "component---src-templates-engagements-js" */),
  "component---src-templates-supervision-js": () => import("./../../../src/templates/supervision.js" /* webpackChunkName: "component---src-templates-supervision-js" */)
}

